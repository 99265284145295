@import "../../../scss/vars";

.date_range_picker {

  :global {
    .DateRangePickerInput, .DateRangePicker{
      display:block;
      padding:0;
    }
    .DateInput_input {
      padding: 4px;
      font-size:15px;
      text-align:center;
    }

    .DateRangePickerInput__withBorder {
      border-radius: 5px;
    }
    .DateInput{
      width:47%;
      margin-left:2px;
    }
    input.DateInput {
      height:34px;
    }

  }
}
