@import "../../scss/vars";

.card {
  margin-bottom: 20px;
  .cardHeader {
    background-color: $light-gray;
  }
}

.input {
  label {
    color: rgba(0, 0, 0, 0.6)!important;
  }
}