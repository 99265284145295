@import "../../../scss/vars";

.datatable {

  :global {
    .selected-row {
      background-color: #888 !important
    }
    .table-bordered th, .table-bordered td{
      padding:5px 20px 5px 5px;
      font-size:14px;
      vertical-align: middle;
    }

    table > thead > tr > th {
      position: relative;
    }

    table > thead > tr > th.has-sorting {
      cursor: pointer;
    }

    table > thead > tr > th:after {
      content: ' ';
      position: absolute;
      height: 0;
      width: 0;
      right: 4px;
      top: 14px;
    }

    table > thead > tr > th.checkbox {
      width: 24px;
    }

    table > thead > tr > th.checkbox:after,
    table > thead > tr > th.actions:after {
      display: none;
    }

    .table-bordered th.checkbox, .table-bordered td.checkbox {
      padding: 5px;
    }

    table > thead > tr > th:hover:after {
      border-top: 5px solid #888;
    }

    table > thead > tr > th.desc:after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 0 solid transparent;
      border-bottom: 5px solid #333;
    }

    table > thead > tr > th.desc:hover:after {
      border-bottom: 5px solid #888;
    }

    table > thead > tr > th.asc:after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #333;
      border-bottom: 5px solid transparent;
    }
  }

}
