@import "../../../scss/vars";

.filter_panel {
  padding-bottom:20px;
  width:100%;

  :global {
    .topActions{
      margin-top: 15px;
      button.dropdown-toggle {
        background-color: $blue;
        border-color: $blue;
      }
    }
  }

  .filter-panel-button-container {
    margin-left: auto;
    display: flex;

    .action-button {
      flex: 0 1 auto;
      padding: 0 15px;
    }

    .actions-popup {
      flex: 0 1 auto;
      padding: 0 15px;
    }
  }
}


