.create_new_entity {
  position: relative;
  padding-top:15px;

  :global {
    .Select-menu-outer{
      z-index: 5;
    }
  }
}

.addNewDataSource {
  margin-left: -0.9375rem;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
  width: 1.4375rem;
}

.removeDataSource {
  margin-left: 1rem;
  width: 1.4375rem;
}