.relationship_form {
  position: relative;
  padding-top:15px;

  :global {
    .row>div{
      margin-bottom:10px;
    }
    .Select-menu-outer{
      z-index: 5;
      max-height: 600px;
    }
    .Select-menu {
      max-height: 600px;
    }
    .Select-clear{
      display: none;
    }

  }
}
