.edit_form {
  position: relative;
  padding-top:15px;

  :global {
    .row>div{
      margin-bottom:10px;
    }
    .Select-menu-outer{
      z-index: 5;
    }
    .Select-clear{
      display: none;
    }
    div.DateRangePicker, div.DateRangePickerInput {
      display:block;
      padding:0;
      border-radius: 7px;
    }
    div.DateInput{
      margin-left:3px;
      width:47%;
    }
    div.DateInput>input{
      height: 38px;
      padding: 5px;
      text-align:center;
    }
  }
}
