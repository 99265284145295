@import './scss/vars';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-weight: $font-weight-regular;
  font-style: normal;
}

:global {
  #root {
  }

  .noPadding {
    padding: 0 !important;
    margin: 0 !important;
  }

  .nanobar {
    .bar {
      background-color: $dirty-gray;
    }
  }
}