.addNewDataSource {
  margin-left: -0.9375rem;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
  width: 1.4375rem;
}

.removeDataSource {
  margin-left: 1rem;
  width: 1.4375rem;
}

.inputText {
  margin-left: 1.5625rem;
  margin-top: -0.625rem;
}

.block {
  margin-bottom: 25px;
}

.form-wrapper {
  margin: 40px 0;
}
