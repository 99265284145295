@import "../../scss/vars";

.userButton {
  float: right;
  margin: 15px;
  :global {
    .dropdown-item.active, .dropdown-item:active {
      background-color: $blue;
    }
  }
}