@import "../../../scss/vars";

.date_filter {

  :global {
    .DateRangePickerInput, .DateRangePicker {
      display: block;
      padding: 0;
    }
    .DateInput_input {
      font-weight: $font-weight-regular;
      padding: 4px;
      font-size: 16px;
      text-align: center;
    }

    .DateRangePickerInput__withBorder {
      border: 1px solid $silver;
      border-radius: 5px;
    }
    .DateInput{
      width: 43%;
      margin-left: 2px;
    }

    .DateRangePickerInput_clearDates {
      padding: 5px;
    }
  }
}
