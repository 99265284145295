$blue: #4A90E2;
$white: #FFFFFF;
$light-blue: #5C9BE4;
$super-light-blue: #F0F8FF;
$dark-blue: #3379CC;
$delimiter-gray: #E0E0E0;
$dirty-gray: #F4F8F9;
$light-gray: #E8E8E8;
$dusty-gray: #9B9B9B;
$silver: #CCCCCC;
$malibu: #87CEFA;
$silver: #c0c0c0;

$header-height: 64px;
$panel-width: 94px;
$panel-image-height: 24px;

// font weights
$font-weight-black: 800;
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-thin: 200;
$font-weight-hairline: 100;
