@import "../../../scss/vars";

.selected {
  position: relative;
  &:after {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 5px;
    background-color: $blue;
  }
}

.navButton {
  padding: 8px 8px 8px (($panel-width - $panel-image-height) / 2 - 5);
  min-height: $panel-image-height;
  .image {
    margin-right: 16px;
    img {
      height: $panel-image-height;
    }
  }
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    text-transform: uppercase;
  }
  text-align: center;
  div {
    padding: 10px 0;
  }
  &:hover {
    background-color: $super-light-blue;
  }
}

.navButton:first-child {
  margin-top: 75px;
}